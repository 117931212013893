import * as S from './styles'

import {
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogLabel,
} from '@reach/alert-dialog'
import { Pagination, SectionUsers, SectionUsersList } from '../../../components'
import {
  AssociatedMasterDealer,
  Pagination as PaginationType,
  UserProfile,
  RegistrationUserResponse,
  IDealerForm,
  UserActivityLog,
  Dealer,
  LogType,
  LogItemType,
  LogData,
} from '@monorepo/interfaces'
import {
  httpUserInfo,
  useQueryParams,
  KeycloakHelper,
  isObject,
  isNonEmptyString,
  ChannelHelper,
  MasterDealerAssociation,
  DealerHelper,
  isNonEmptyArray,
  LogHelper,
  isArrayEqual,
  isStringEqualCI,
  UserActivityLogKey,
  UserHelper,
  useDebounce,
  isNumeric,
} from '@monorepo/infra'
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'

import { DashboardBackButton, ExportXlsLink } from '../../atoms'
import { DealerForm } from '../../organisms'
import { UserForm } from './forms/userForm'
import useTextTranslation from '@monorepo/infra/build/locales/i18next/useLocation'
import axios from 'axios'
import * as TreeHelper from '../../helpers/user'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { useKeycloak } from '@react-keycloak/web'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import {
  SearchTerm,
  ITeamMembersListFilter,
  TeamMembersListFilter,
  filterToURLSearchParams,
} from '@monorepo/components'

type UsersFetch = PaginationType & {
  data: UserProfile[]
}

const directtvAccounts = [
  DealerHelper.masterDealerIdDirectvEmployeesOption,
  DealerHelper.masterDealerIdDirectvISPDealersOption,
  DealerHelper.masterDealerIdDirectvNDDealersOption,
]

const getPageNumber = (pageNumberStr?: string): number => {
  const pageNumber = isNumeric(pageNumberStr)
    ? parseInt(pageNumberStr as string)
    : 1
  return pageNumber > 0 ? pageNumber : 1
}

const Users = (): JSX.Element => {
  const directoryBaseUrl = '/user/directory'

  const { keycloak } = useKeycloak()
  const user = KeycloakHelper.getTokenParsed(keycloak)
  const canAddUser =
    UserHelper.isImpersonatedUserSuperAdmin(user?.groups) ||
    UserHelper.isSuperAdmin(user?.groups) ||
    UserHelper.isImpersonatedUserDirectvAdmin(user?.groups) ||
    UserHelper.isDirectvAdmin(user?.groups)

  const searchURLKey = 's'
  const pageURLKey = 'p'
  const queryParams = useQueryParams()
  const [search, setSearch] = useState(queryParams.get(searchURLKey) ?? '')
  const debouncedSearch = useDebounce(search, 1000)
  const [page, setPage] = useState(
    getPageNumber(queryParams.get(pageURLKey) as string)
  )

  const [isManageDialogOpened, setIsManageDialogOpened] = useState(false)
  const manageCancelRef = useRef(null)

  const { t } = useTextTranslation('admin')

  const [users, setUsers] = useState<UsersFetch>()
  const [userToEdit, setUserToEdit] = useState<IDealerForm>()
  const [loading, setLoading] = useState(true)

  const previousApiUrl = useRef<string | undefined>(undefined)

  const [isCreateUserDialogOpened, setIsCreateUserDialogOpened] =
    useState(false)
  const [createUserError, setCreateUserError] = useState<
    string | null | undefined
  >(undefined)
  const [currentUserProfile, setCurrentUserProfile] = useState<
    RegistrationUserResponse | undefined
  >(undefined)
  const [isCurrentUserDataLoading, setIsCurrentUserDataLoading] =
    useState(false)
  const [principalEmails, setPrincipalEmails] = useState<
    string[] | null | undefined
  >(undefined)
  const [filter, setFilter] = useState<
    ITeamMembersListFilter | null | undefined
  >(undefined)

  useEffect(() => {
    const getPrincipalEmails = async () => {
      try {
        const response = await axios.get('/United/DealerUniqueEmails', {
          baseURL: process.env.REACT_APP_TX_BASE_URL,
        })

        setPrincipalEmails(
          response.status === 200 &&
            isNonEmptyArray(response.data?.DealerEmails)
            ? response.data?.DealerEmails
            : undefined
        )
      } catch (error) {
        console.log(error)
      }
    }

    getPrincipalEmails()
  }, [])

  useEffect(() => {
    const currentUserEmail = KeycloakHelper.getCurrentUserEmail(keycloak)
    if (!currentUserEmail) {
      return
    }

    setIsCurrentUserDataLoading(true)

    httpUserInfo
      .get({ url: `/dealer/${currentUserEmail}` })
      .then((profile) => {
        const userProfile = profile.data as RegistrationUserResponse
        setCurrentUserProfile(isObject(userProfile) ? userProfile : undefined)
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setIsCurrentUserDataLoading(false)
      })
  }, [keycloak])

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const handleTeamMemberListFilterChange = (
    filter?: ITeamMembersListFilter
  ) => {
    setPage(1)
    setFilter(filter)
  }

  const getUsers = async (isReload?: boolean) => {
    const searchParams = new URLSearchParams()
    searchParams.append('page', page.toString())
    searchParams.append('pageSize', '20')
    searchParams.append('withDealers', 'true')
    searchParams.append('withCoopAllowances', 'true')

    if (isNonEmptyString(debouncedSearch) && debouncedSearch.length > 2) {
      searchParams.append('search', encodeURIComponent(debouncedSearch))
    }

    filterToURLSearchParams(searchParams, filter as ITeamMembersListFilter)

    const apiURL = `${directoryBaseUrl}?${searchParams.toString()}`

    if (!isReload && previousApiUrl.current === apiURL) {
      return
    }

    previousApiUrl.current = apiURL
    setLoading(true)
    const usersList = await httpUserInfo.get<UsersFetch>({
      url: apiURL,
    })

    setUsers(usersList.data)
    setLoading(false)
  }

  useEffect(() => {
    getUsers()
  }, [debouncedSearch, filter, page])

  const toggleModal = useCallback(() => {
    setIsManageDialogOpened((isOpened) => !isOpened)
  }, [])

  const handleOpenModalEditUser = (index: number) => {
    const data = users?.data[index] as UserProfile
    const userForm: IDealerForm = {
      userId: data?._id as string,
      uniqueDealerId: data?.dealer?.uniqueDealerId,
      businessName: data?.dealer?.businessName,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      dealerTypes: data?.dealerTypes || [],
      enabled: data?.enabled || false,
      associatedMasterDealers:
        ChannelHelper.getMasterDealerIds(
          data?.dealer?.associatedMasterDealers as MasterDealerAssociation[]
        ) || [],
      groups: data?.groups,
      hasCoop: data?.dealer?.hasCoop,
      channels: ChannelHelper.getAssociatedMasterDealerChannels(
        data?.dealer?.associatedMasterDealers as MasterDealerAssociation[]
      ),
      coopAllowances: data?.dealer?.coopAllowances,
    }
    setUserToEdit(userForm)
    setIsManageDialogOpened(true)
  }

  // const { data, ...pagination } = users
  // pagination.page = Number(1)
  const handleClearSearch = () => setSearch('')

  const handleDeleteUser = async (userId: string, email: string) => {
    if (!isNonEmptyString(userId) || !isNonEmptyString(email)) {
      return
    }

    const result = window.confirm('Are you sure?')
    if (!result) {
      return
    }

    try {
      await httpUserInfo.delete({ url: `/dealer/byuser/${email}` })
      await httpUserInfo.delete({ url: `/user/${email}` })

      LogHelper.logAsync(
        {
          userActivity: {
            userId,
            changes: [
              {
                key: UserActivityLogKey.TEAM_MEMBER_DELETED,
                previusValue: 'undefined',
                currentValue: email,
              },
            ],
          },
          type: LogType.USER_ACTIVITY,
          itemType: LogItemType.DEALER_DELETING,
        } as LogData,
        KeycloakHelper.getToken(keycloak)
      )

      getUsers(true)
    } catch (err) {
      console.error(err)
    }
  }

  const handleUserActivity = async (
    data: IDealerForm,
    userId?: string,
    changes?: UserActivityLog[]
  ) => {
    const previousData = users?.data.find((u) => u._id === data.userId)
    const currentChanges = DealerHelper.getChanges(
      previousData as unknown as Dealer,
      data,
      userId,
      changes
    )

    if (isNonEmptyArray(currentChanges.changes)) {
      LogHelper.logAsync(
        {
          userActivity: currentChanges,
          type: LogType.USER_ACTIVITY,
          itemType: LogItemType.DEALER_DATA_CHANGING,
        } as LogData,
        KeycloakHelper.getToken(keycloak)
      )
    }
  }

  const handleEditUser = async (data: IDealerForm) => {
    try {
      const userData = {
        userId: data.userId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        dealerTypes: data.dealerTypes,
        enabled: data.enabled,
        groups: data.groups,
      }

      handleUserActivity(data)

      await httpUserInfo.patch({
        url: `/user/${data.userId}`,
        data: userData,
      })

      const dealer = cloneDeep(
        users?.data.find((u) => u._id === data.userId)?.dealer
      )
      if (dealer) {
        DealerHelper.patchAssociatedMasterDealers(
          dealer.associatedMasterDealers
        )

        if (
          !isArrayEqual(
            dealer.associatedMasterDealers,
            data.associatedMasterDealers,
            (left: AssociatedMasterDealer, right: string) =>
              isStringEqualCI(left.masterDealerId, right)
          )
        ) {
          const associatedMasterDealers =
            DealerHelper.intersectAssociatedMasterDealers(
              dealer.associatedMasterDealers,
              data.associatedMasterDealers
            )

          await httpUserInfo.patch({
            url: `/dealer/${dealer._id}`,
            data: {
              _id: dealer._id,
              userId: dealer.userId || data.userId,
              associatedMasterDealers,
              hasCoop: dealer.hasCoop,
              ...(!dealer._id && { userEmail: data.email }),
            },
          })
        }
      }

      setIsManageDialogOpened(false)
      getUsers(true)
    } catch (err) {
      console.error(err)
    }
  }

  const handleExportXls = async () => {
    try {
      const responses = await Promise.all([
        axios.get('/masterdealer/tree', {
          baseURL: process.env.REACT_APP_REST_USER_BASE_URL,
          headers: {
            Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
          },
        }),
        axios.get('/United/DealerUniqueEmails', {
          baseURL: process.env.REACT_APP_TX_BASE_URL,
        }),
      ])

      if (Array.isArray(responses) && responses.length > 1) {
        const data = TreeHelper.getTreeDataToExport(
          responses[0].data,
          responses[1].data?.DealerEmails
        )
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset-UTF-8'
        const fileExtension = '.xlsx'
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = { Sheets: { Sheet1: ws }, SheetNames: ['Sheet1'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const outputData = new Blob([excelBuffer], { type: fileType })
        const dateStr = moment().format('MMDDYYYY')
        FileSaver.saveAs(outputData, `Directory${dateStr}${fileExtension}`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const createDealer = async (data: IDealerForm) => {
    try {
      const createDealerResponse = await DealerHelper.createDealerAsync(data)
      if (isNonEmptyString(createDealerResponse)) {
        setCreateUserError(createDealerResponse)
        return
      }

      handleUserActivity(
        data,
        (createDealerResponse.data as UserProfile)._id as string,
        [
          {
            key: UserActivityLogKey.TEAM_MEMBER_ADDED,
            previusValue: 'undefined',
            currentValue: (data.email as string) || 'undefined',
          },
          {
            key: UserActivityLogKey.DEALER_ID,
            previusValue: 'undefined',
            currentValue:
              (
                createDealerResponse.data as UserProfile
              )?.dealer?._id?.toString() || 'undefined',
          },
        ]
      )

      setCreateUserError(undefined)
      setIsCreateUserDialogOpened(false)
      getUsers(true)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <S.Main>
      <DashboardBackButton redirectURL="/admin" text="Back to Admin" />
      <SectionUsers title="User Management" />
      <S.PageActions>
        <S.ActionButtonsContainer>
          <ExportXlsLink onClick={handleExportXls} />
          {isManageDialogOpened && (
            <S.AlertDialogOverlay
              onDismiss={toggleModal}
              leastDestructiveRef={manageCancelRef}
            >
              <S.AlertDialog
                onDismiss={toggleModal}
                leastDestructiveRef={manageCancelRef}
              >
                <AlertDialogContent
                  style={{
                    background: '#fff',
                    padding: '0 20px',
                    margin: '1vh auto',
                  }}
                >
                  <AlertDialogLabel></AlertDialogLabel>
                  <AlertDialogDescription>
                    <UserForm
                      close={toggleModal}
                      actionForm={handleEditUser}
                      initialValues={userToEdit}
                    />
                  </AlertDialogDescription>
                </AlertDialogContent>
              </S.AlertDialog>
            </S.AlertDialogOverlay>
          )}
          {canAddUser ? (
            <S.CreateUserButton
              label={t('section_users.button_text')}
              colorOption="blue"
              onClick={() => setIsCreateUserDialogOpened(true)}
              disabled={isCurrentUserDataLoading}
            />
          ) : undefined}
        </S.ActionButtonsContainer>
      </S.PageActions>
      <S.Separator />
      <S.SearchFiltersContainer>
        <S.Search
          placeholder={t('section_users_list.input_placeholder')}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
        <SearchTerm searchTerm={search} onClear={handleClearSearch} />
      </S.SearchFiltersContainer>
      <TeamMembersListFilter onChange={handleTeamMemberListFilterChange} />
      <SectionUsersList
        loading={loading}
        users={users?.data as UserProfile[]}
        principalEmails={principalEmails as string[]}
        handleEditUser={handleOpenModalEditUser}
        handleDeleteUser={handleDeleteUser}
      />
      <S.PaginationContainer>
        <Pagination
          onPageChange={handlePageChange}
          totalCount={users?.totalCount as number}
          page={page}
          pageSize={users?.pageSize as number}
          totalPages={users?.totalPages as number}
        />
      </S.PaginationContainer>
      <S.CreateUserDialog
        aria-label="CreateUserDialog"
        isOpen={isCreateUserDialogOpened}
        onDismiss={() => setIsCreateUserDialogOpened(false)}
      >
        <S.CreateUserDialogContent aria-label="CreateUserDialogContent">
          <DealerForm
            currentUserProfile={currentUserProfile}
            additionalMasterDealerIdsOptions={{
              position: 'top',
              options: directtvAccounts,
            }}
            actionForm={createDealer}
            close={() => setIsCreateUserDialogOpened(false)}
            error={createUserError as string}
          />
        </S.CreateUserDialogContent>
      </S.CreateUserDialog>{' '}
    </S.Main>
  )
}

export default Users
